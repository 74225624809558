import React from 'react';
import './App.css';
import { ReactComponent as Logo } from '../resources/images/Statlux_logo_full.svg';

function App() {
    return (
        <div className='App'>
            <div>
                <Logo
                    fill={'#000000'}
                    width={240}
                />
            </div>
        </div>
    );
}

export default App;
